const i18n = {
	presidentAdministrationText: {
		1 : ["Администрация Президента", "Республики Беларусь"],
		0 : ["Адміністрацыя Прэзідэнта", "Рэспублікі Беларусь"]
	},
	footerItems: {
		1: [
			"Новости",
			"Контакты",
			"Помощь"
		],
		0: [
			"Навіны",
			"Кантакты",
			"Дапамога"
		]
	},
	copyrightFooter: {
		1 : ["© РУП «Центр цифрового развития»"],
		0 : ["© РУП «Цэнтр лічбавага развіцця»"]
	},
	ministersCouncilText: {
		1 : ["Аппарат Совета Министров", "Республики Беларусь"],
		0 : ["Апарат Савета Міністраў", "Рэспублікі Беларусь"]
	},
	investigateCommiteeText: {
		1 : ["Следственный комитет", "Республики Беларусь"],
		0 : ["Следчы камітэт", "Рэспублікі Беларусь"]
	},
	stateSecurityCommiteeText: {
		1 : ["Комитет государственной безопасности", "Республики Беларусь"],
		0 : ["Камітэт дзяржаўнай бяспекі", "Рэспублікі Беларусь"]
	},
	stateControlCommiteeText: {
		1 : ["Комитет государственного контроля", "Республики Беларусь"],
		0 : ["Камітэт дзяржаўнага кантролю", "Рэспублікі Беларусь"]
	},
	nationalBankText: {
		1 : ["Национальный банк", "Республики Беларусь"],
		0 : ["Нацыянальны банк", "Рэспублікі Беларусь"]
	}
}

export default i18n
